Gri.module({
  name: 'modal-beta',
  ieVersion: null,
  $el: $('.modal-beta'),
  container: '.modal-beta',
  fn: function () {

    const $this = this.$el;

    $this.find('.modal-close').on('click', function () {
      $this.modal('hide');
    });

    $(window).on('modal-video', function (event, data) {
      var $html = $($('[tpl="modal-video"]').clone().removeAttr("tpl")).clone();
      $this.find('.modal-content').html($html);
      if(data){
        $this.find('.modal-content').html(data.content);
      }
      $this.modal('show')
    });

    // $(window).trigger('modal-video',{content: [html]})

  }
});
